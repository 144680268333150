import request from "@/utils/request";

// StockInRecord
export function stockInRecordList(params) {
  return request({ url: `/stock_in_records/`, method: "get", params });
}

export function stockInRecordCreate(data) {
  return request({ url: `/stock_in_records/`, method: "post", data });
}

export function stockInRecordVoid(data) {
  return request({ url: `/stock_in_records/${data.id}/void/`, method: "post", data });
}

export function stockInRecordImport(data) {
  return request({
    url: "/stock_in_records/import_data/",
    method: "post",
    data,
  });
}

export function stockInRecordTemplate(params) {
  return request({
    url: "/stock_in_records/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}
