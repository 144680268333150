import request from "@/utils/request";

// RoleOption
export function roleOption(params) {
  return request({ url: `/roles/options/`, method: "get", params });
}

// UserOption
export function userOption(params) {
  return request({ url: `/users/options/`, method: "get", params });
}

// Warehouse
export function warehouseOption(params) {
  return request({ url: `/warehouses/options/`, method: "get", params });
}

// Area
export function areaOption(params) {
  return request({ url: `/areas/options/`, method: "get", params });
}

// Location
export function locationOption(params) {
  return request({ url: `/locations/options/`, method: "get", params });
}

// Client
export function clientOption(params) {
  return request({ url: `/clients/options/`, method: "get", params });
}

// Category
export function categoryOption(params) {
  return request({ url: `/categories/options/`, method: "get", params });
}

// MaterialCategory
export function materialCategoryOption(params) {
  return request({ url: `/material_categories/options/`, method: "get", params });
}

// Material
export function materialOption(params) {
  return request({ url: `/materials/options/`, method: "get", params });
}

// Batch
export function batchOption(params) {
  return request({ url: `/batchs/options/`, method: "get", params });
}

// Inventory
export function inventoryOption(params) {
  return request({ url: `/inventories/options/`, method: "get", params });
}
